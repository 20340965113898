import React, { useEffect, useRef, useState } from "react";
import Typed from "typed.js";
import { Box, Button, Typography, Paper, makeStyles, Grid, TextField } from "@material-ui/core";
import LoginPopup from "./LoginPopup";
import { FaArrowRight } from "react-icons/fa";
import * as images from "../../assets/images";
import Games from '../home/Games';
import ParallaxSection from "./ParallaxSection";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  ctaGradientBackground: {
    background: 'linear-gradient(90deg, #23b5b5, #2563eb)', 
    padding: theme.spacing(5),
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',  // Default to column direction
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      gap: '5px',  // Reduce gap on small screens for better spacing
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',  // Change to row direction on medium and larger screens
      gap: '15px',  // Increase gap on medium screens
    },
    [theme.breakpoints.up('lg')]: {
      gap: '20px',  // Further increase gap on large screens
    },
  },
  downloadImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingInline: '2px',
    [theme.breakpoints.down('sm')]: {
      paddingInline: '1px',  // Reduce padding on smaller screens
      flexDirection: 'column',  // Stack items vertically on small screens if needed
    },
    [theme.breakpoints.up('md')]: {
      paddingInline: '5px',  // Increase padding slightly on medium screens
    },
    [theme.breakpoints.up('lg')]: {
      paddingInline: '10px',  // Increase padding on large screens
    },
  },
  downloadImage: {
    padding: '2px',
    width: 'auto',
    height: 'auto',
    cursor: 'pointer',
    transition: 'transform 0.3s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  container: {
    minHeight: '480px',
    backgroundColor: "#2563eb",
    color: 'white',
    position: 'relative',
    padding: "125px",
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(2),
    marginTop: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
  },
  heading: {
    fontSize: "2.5rem",
    fontWeight: "normal",
    fontFamily: "Poppins",
    padding: theme.spacing(0, 0),
    [theme.breakpoints.up("md")]: {
      fontSize: "3rem",
      marginBottom: theme.spacing(2, 3),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  runningHeading: {
    height: "180px",
    fontSize: "2.5rem",
    fontWeight: "bold",
    fontFamily: "Poppins",
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up("md")]: {
      fontSize: "3rem",
      height: "150px",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  description: {
    marginBottom: theme.spacing(1),
    fontFamily: "Poppins",
  },
  button: {
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    fontFamily: "Poppins",
    padding: theme.spacing(1.5, 3),
    borderRadius: "4px",
    boxShadow: theme.shadows[2],
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    "&:hover": {
      transform: 'scale(1.1)',
      backgroundColor: "#f1f1f1",
    },
  },
  formButton: {
    marginTop: theme.spacing(2),
    backgroundColor: "#2563eb",
    color: "#fff",
    fontFamily: "Poppins",
    cursor: "pointer",
    padding: "12px 16px",
    "&:hover": {
      backgroundColor: "#1d4ed8",
    },
  },
  textField: {
    "& .MuiInputBase-root": {
      fontFamily: "Poppins",
      height: "100%",
    },
    "& .MuiInputLabel-root": {
      fontFamily: "Poppins",
    },
    margin: theme.spacing(1, 0),
  },
  section: {
    paddingInline: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    gap: theme.spacing(4),
  },
  sectionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingInline: '125px',
    marginTop: '20px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      paddingInline: theme.spacing(2),
      gap: theme.spacing(3),
    },
  },
  micIcon: {
    fontSize: '2rem',
    color: '#2563eb',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    margin: '0 auto',
    marginBottom: '35px',
    animation: '$bounce 2s infinite',
    fontFamily: 'Poppins',
  },
  '@keyframes bounce': {
    '0%, 20%, 50%, 80%, 100%': {
      transform: 'translateY(0)',
    },
    '40%': {
      transform: 'translateY(-30px)',
    },
    '60%': {
      transform: 'translateY(-15px)',
    },
  },
  sectionTitle: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 'bold',
    color: "#2563eb",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  sectionText: {
    fontFamily: "Poppins",
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  image: {
    justifyContent: 'center',
    width: "75%",
    minHeight: 50,
    maxHeight: "50%",
    objectFit: "fill",
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%', 
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));


function Home() {
  const classes = useStyles();
  const [open, setOpen] = useState(false); // State for controlling popup visibility
  const history = useHistory();

  const token = localStorage.getItem('authtoken');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleclose = () =>{
    setOpen(false);
  };


  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "Speak with Confidence",
        "Learn Through Real Scenarios",
        "Achieve Fluency with AI & Tutors",
      ],
      typeSpeed: 100,
      backSpeed: 50,
      backDelay: 2000,
      loop: true,
    });
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div>
      <Box className={classes.container}>
        <Box className={classes.content}>
          <Typography
            component="div"
            fontFamily="Poppins"
            className={classes.heading}
          >
            Unlock your dream language{" "}
          </Typography>
          <Typography
            component="div"
            fontFamily="Poppins"
            className={classes.runningHeading}
          >
            <span ref={el}></span>
          </Typography>
          <Typography className={classes.description}>
              Boost your language skills with hands-on practice and smart AI guidance.
          </Typography>
          {/* {
            token ? (
                <Button
                  variant="contained"
                  className={classes.button}
                  endIcon={<FaArrowRight/>}
                  onClick={() => history.push("/dashboard")}
                >
                  
                     GO TO DASHBOARD
                  
                </Button>

            )

            :(

            <Button
              variant="contained"
              className={classes.button}
              endIcon={<FaArrowRight/>}
              onClick={handleOpen }
            >
              
                 GET STARTED NOW
              
            </Button>
           
            )
          } */}
          <Button
          variant="contained"
          className={classes.button}
          endIcon={<FaArrowRight/>}
          onClick={()=> history.push("/dashboard")}>
            See the Demo (Guest)

          </Button>
        </Box>
      </Box>

      <Box>
        <ParallaxSection />
      </Box>

      <div className={classes.ctaGradientBackground} >
        <Grid container spacing={3} className={classes.sectionContainer}>
          <Grid item xs={12} md={7} className={classes.sectionContainer}>
            <Grid>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.micIcon}
                style={{ fontWeight:'bold', color:'white'}}
              >
                Begin your language learning journey by downloading our app now!
              </Typography>
             

              <div className={classes.root}>
                <Grid container spacing={16}>
                  <Grid item xs={6} className={classes.downloadImageContainer}>
                    <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
                      <img
                        src="https://static.wixstatic.com/media/7c42a0_1492a6941cb84052860c4203e92ea75c~mv2.png/v1/fill/w_169,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Google%20play.png"
                        alt="playStore"
                        className={classes.downloadImage}
                      />
                    </a>
                  </Grid>
                  <Grid item xs={6} className={classes.downloadImageContainer}>
                    <a href="https://www.apple.com/in/app-store/" target="_blank" rel="noopener noreferrer">
                      <img
                        src="https://static.wixstatic.com/media/7c42a0_96deac31051d45518eb226bdc1bb2931~mv2.png/v1/fill/w_172,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/app%20store.png"
                        alt="appStore"
                        className={classes.downloadImage}
                      />
                    </a>

                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <LoginPopup open={open} onClose={handleclose}/>
      </div>


      <div className={classes.section}>
        <Grid container spacing={4} className={classes.sectionContainer}>
          <Grid item xs={12} md={7}>
            <Typography
              variant="h3"
              gutterBottom
              className={classes.sectionTitle}
            >
              Book Experts
            </Typography>
            <Typography
              variant="body1"
              color="black"
              paragraph
              className={classes.sectionText}
            >
              Connect with seasoned language experts who provide personalized guidance and insights to accelerate your learning journey
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <img src={images.personalisedimg} alt="random" className={classes.image} />
          </Grid>
        </Grid>

        

        <Grid container spacing={4} className={classes.sectionContainer}>
          <Grid item xs={12} md={5}>
            <img src={images.faq} alt="random" className={classes.image} />
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography
              variant="h3"
              gutterBottom
              className={classes.sectionTitle}
            >
              AI chatbot
            </Typography>
            <Typography
              variant="body1"
              color="black"
              paragraph
              className={classes.sectionText}
            >
              Engage in dynamic conversations with AI, offering instant feedback and support to refine your language skills.
            </Typography>
      
          </Grid>
        </Grid>

        <Grid container spacing={4} className={classes.sectionContainer}>
         
          <Grid item xs={12} md={7}>
            <Typography
              variant="h3"
              gutterBottom
              className={classes.sectionTitle}
            >
              Videos
            </Typography>
            <Typography
              variant="body1"
              color="black"
              paragraph
              className={classes.sectionText}
            >
              Immerse yourself in real-life scenarios of your favourite domain through videos that enhance your understanding and application of the language.
            </Typography>
            {/* <Typography variant="body1" className={classes.underlineText}>
              Get in touch
            </Typography> */}
          </Grid>
          <Grid item xs={12} md={5}>
            <img src={images.vdo} alt="random" className={classes.image} />
          </Grid>

        </Grid>
        
      </div>
      
      <Games className={classes.container}/>
    </div>
  );
}

export default Home;

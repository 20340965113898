import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import StarIcon from '@material-ui/icons/Star';
import SearchIcon from '@material-ui/icons/Search';
import * as images from "../../assets/images";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    justifyContent: 'center',
  },
  filterGrid: {
    marginBottom: theme.spacing(3),
  },
  leftGrid: {
    paddingRight: theme.spacing(2),
  },
  rightGrid: {
    paddingLeft: theme.spacing(2),
  },
  card: {
    width: '100%',
    display: 'flex',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
  },
  cardMedia: {
    width: '100%',
  },
  cardContent: {
    flex: '1 0 auto',
    fontFamily:'Poppins, sans-Serif'
  },
  heartIcon: {
    color: 'grey',
    '&.liked': {
      color: 'red',
    },
  },
  highlight: {
    backgroundColor: theme.palette.primary.light,
    padding: '2px 4px',
    borderRadius: '4px',
    display: 'inline-block',
  },
  buttons: {
    marginTop: theme.spacing(1),
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 120,
  },
  teacherImage: {
    width: '100px',
    height: '100px',
    borderRadius: '8px',
    objectFit: 'cover',
  },
}));

const Tutors = () => {
  const classes = useStyles();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [language, setLanguage] = useState('');
  const [price, setPrice] = useState('');
  const [rating, setRating] = useState('');
 

  // Sample data for multiple teachers
  const [teachersList, setTeachersList] = useState([
    {
      id: 1,
      name: 'John Doe',
      image: images.teacher,
      location: 'New York, USA',
      flag: 'https://flagcdn.com/w320/us.png',
      post: 'Super Tutor',
      subject: 'Mathematics',
      experience: '10 years',
      languages: 'English, Spanish',
      description: 'Highly experienced tutor specializing in advanced math...',
      rating: 4.9,
      fee: 50,
      liked: false,
    },
    {
      id: 2,
      name: 'Jane Smith',
      image: images.teacher,
      location: 'London, UK',
      flag: 'https://flagcdn.com/w320/gb.png',
      post: 'Senior Tutor',
      subject: 'Physics',
      experience: '8 years',
      languages: 'English, French',
      description: 'Passionate about teaching and helping students excel...',
      rating: 4.7,
      fee: 45,
      liked: false,
    },
    // Add more teachers as needed
  ]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleLike = (id) => {
    setTeachersList(prevState =>
      prevState.map(teacher =>
        teacher.id === id ? { ...teacher, liked: !teacher.liked } : teacher
      )
    );
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleRatingChange = (event) => {
    setRating(event.target.value);
  };

  const filteredTeachers = teachersList.filter((teacher) =>
    teacher.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (!language || teacher.languages.toLowerCase().includes(language.toLowerCase())) &&
    (!price || teacher.fee <= price) &&
    (!rating || teacher.rating >= rating)
  );

  return (
    <div className={classes.root}>
      {/* Search Filters */}
      <Grid container spacing={2} className={classes.filterGrid} justify='center'>
        <Grid item xs={12} md={10}>
          <FormControl variant="outlined" className={classes.formControl} fullWidth>
            <TextField
              label="Search Tutor"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Type a tutor's name"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Language</InputLabel>
            <Select label="Language" value={language} onChange={handleLanguageChange}>
              <MenuItem value="English">English</MenuItem>
              <MenuItem value="Spanish">Spanish</MenuItem>
              <MenuItem value="French">French</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Price</InputLabel>
            <Select label="Price" value={price} onChange={handlePriceChange}>
              <MenuItem value={30}>$30/hr</MenuItem>
              <MenuItem value={50}>$50/hr</MenuItem>
              <MenuItem value={70}>$70/hr</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Rating</InputLabel>
            <Select label="Rating" value={rating} onChange={handleRatingChange}>
              <MenuItem value={4}>4+ Stars</MenuItem>
              <MenuItem value={4.5}>4.5+ Stars</MenuItem>
              <MenuItem value={5}>5 Stars</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Left Grid - 3/4 */}
        <Grid item xs={12} md={10} className={classes.leftGrid}>
          {filteredTeachers.map((teacher) => (
            <Card className={classes.card} key={teacher.id}>
              <CardMedia
                className={classes.cardMedia}
                image={teacher.image}
                title={teacher.name}
              />
              <div className={classes.cardContent}>
                <CardContent>
                  <Grid container spacing={2}>
                    {/* Image on the left */}
                    <Grid item xs={6} sm={3} md={2}>
                      <img src={teacher.image} alt={teacher.name} className={classes.teacherImage} />
                    </Grid>

                    {/* Description on the right */}
                    <Grid item xs={12} sm={9} md={10}>
                      <Typography variant="h6">{teacher.name}</Typography>
                      <Typography variant="body2">
                        <img
                          src={teacher.flag}
                          alt="Country Flag"
                          width="20"
                          height="12"
                          style={{ marginRight: 8 }}
                        />
                        {teacher.location}
                      </Typography>
                      <Typography variant="body2" className={classes.highlight}>
                        {teacher.post}
                      </Typography>
                      <Typography variant="body2">Subject: {teacher.subject}</Typography>
                      <Typography variant="body2">Professional: {teacher.experience}</Typography>
                      <Typography variant="body2">Languages: {teacher.languages}</Typography>
                      <Typography variant="body2">
                        Description: {teacher.description} <Button color="primary">Read more</Button>
                      </Typography>
                      <div className={classes.buttons}>
                        <Button variant="contained" color="primary">Book Trial Lesson</Button>
                        <Button variant="contained" color="secondary">Send Message</Button>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <StarIcon style={{ color: 'gold' }} />
                        <Typography variant="body2" style={{ marginLeft: 4 }}>{teacher.rating}</Typography>
                        <Typography variant="body2" style={{ marginLeft: 16 }}>Fee: ${teacher.fee}/hr</Typography>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </div>
              <IconButton 
                className={`${classes.heartIcon} ${teacher.liked ? 'liked' : ''}`} 
                onClick={() => handleLike(teacher.id)}
              >
                <FavoriteIcon />
              </IconButton>
            </Card>
          ))}
        </Grid>
      </Grid>

      
    </div>
  );
};

export default Tutors;

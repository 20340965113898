import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import Dashboard from "./components/userdashBoard/Dashboard";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Privacy from "./components/footer/Privacy";
import Terms from "./components/footer/Terms";
import Landing from "./components/beATutor/Landing";
import DashBoardTeacher from "./components/beATutor/DashBoardTeacher";
import Disclaimer from "./components/footer/Disclaimer";

function App() {
  const location = useLocation();
  return (
    <>
      {location.pathname !== "/dashboard" 
      && location.pathname!=="/privacy-policy" 
      && location.pathname!=="/terms-of-service" 
      && location.pathname!=="/be-a-tutor"
      && location.pathname!=="/teacher-dashboard"
      && <Navbar />}
     

      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/privacy-policy" component={Privacy}/>
        <Route path="/terms-of-service" component={Terms}/>
        <Route path="/be-a-tutor" component={Landing}/>
        <Route path="/teacher-dashboard" component={DashBoardTeacher}/>
        <Route path="/disclaimer" component={Disclaimer} />

      </Switch>
      {location.pathname !== "/dashboard" &&  location.pathname!=="/teacher-dashboard" && <Footer />}
     
    </>
  );
}

export default App;

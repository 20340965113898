import React, { useState } from "react";
import {
  Container,
  Grid,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SideBar from "./SideBar";
import Profile from "./Profile";
import Courses from "./Courses";
import Apps from "./Apps";
import Tutors from "./Tutors";
import ChatBot from "./ChatBot";
import Widgets from "./Widgets";
import Stream from "./Stream";
import Landing from "../beATutor/Landing";
import Notes from "./Notes";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    display: "flex",
  },
  mainContent: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: props => props.open ? theme.spacing(28) : theme.spacing(8),
    width: `calc(100% - ${props => props.open ? 228 : 64}px)`,
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
}));

const LoadingComponent = () => (
  <Box className={useStyles().loadingContainer}>
    <CircularProgress />
  </Box>
);

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Dashboard");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const classes = useStyles({ open: sidebarOpen });

  const handleLoading = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1200);
  };

  const renderContent = () => {
    switch (selectedItem) {
      case "Profile":
        return <Profile />;
      case "Courses":
        return <Courses />;
      case "Stream":
        return <Stream />;
      case "Playground":
        return <ChatBot />;
      case "Apps":
        return <Apps />;
      case "Tutors":
        return <Tutors />;
      case "Be A Tutor":
        return <Landing />;
      case "Notes":
        return <Notes />;
      default:
        return <Widgets />;
    }
  };

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <Container maxWidth="lg" className={classes.container}>
          <SideBar
            selectedItem={selectedItem}
            onItemSelected={(item) => setSelectedItem(item)}
            onTypographyClick={handleLoading}
            open={sidebarOpen}
            setOpen={setSidebarOpen}
          />
          <Grid container spacing={2} className={classes.mainContent}>
            <Grid item xs={12}>
              {renderContent()}
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Card,
  CardMedia,
  Container,
  Grid,
  Typography,
  makeStyles,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tabs,
  Tab,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Slide from "@material-ui/core/Slide";
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(4),
    textAlign: "left",
    fontFamily: "Poppins",
  },
  card: {
    position: "relative",
    width: "100%",
    fontFamily: "Poppins",
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,
    transition: "transform 0.3s ease-in-out",
    border: `2px solid ${theme.palette.primary.main}`,
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: theme.shadows[10],
    },
    padding: theme.spacing(2),
    textAlign: "left",
  },
  cardMedia: {
    width: "100%",
    height: 120,
    objectFit: "cover",
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  addCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 150,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    cursor: "pointer",
    transition: "transform 0.3s ease-in-out",
    border: `2px dashed ${theme.palette.primary.main}`,
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: theme.shadows[10],
    },
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    fontFamily: "Poppins",
  },
  cardContent: {
    textAlign: "left",
  },
  cardTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  cardDescription: {
    color: theme.palette.text.secondary,
  },
  assistantBox: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
  },
  assistantToggle: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  textEditorContainer: (props) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    ...(props.isAssistantOpen && {
      marginRight: theme.spacing(2),
    }),
  }),
  notesList: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: "flex",
    height: "100vh",
  },
  addSubnoteButton: {
    marginBottom: theme.spacing(1),
  },
  subnoteItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
  tabContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const extractImageUrl = (coverImage) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = coverImage;
  const imgElement = tempDiv.querySelector("img");
  return imgElement ? imgElement.src : tempDiv.textContent.trim();
};

function Widgets() {
  const classes = useStyles();
  const initialItems = [
    {
      id: "item-0",
      title: "Sample Note",
      description: "This is a sample note",
      coverImage: "https://cdn-icons-png.flaticon.com/512/5762/5762492.png",
      subnotes: [],
    },
  ];

  const [items, setItems] = useState(() => {
    const savedItems = localStorage.getItem("draggedItems");
    return savedItems ? JSON.parse(savedItems) : initialItems;
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [newTitle, setNewTitle] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newCoverImage, setNewCoverImage] = useState("");
  const [isAssistantOpen, setIsAssistantOpen] = useState(true);
  const [newSubnote, setNewSubnote] = useState("");
  const [selectedNote, setSelectedNote] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    localStorage.setItem("draggedItems", JSON.stringify(items));
  }, [items]);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(reorderedItems);
  };

  const handleAddNew = () => {
    setEditingItem(null);
    setNewTitle("");
    setNewDescription("");
    setNewCoverImage("");
    setSelectedNote(null);
    setOpenDialog(true);
  };

  const handleSaveNote = () => {
    if (editingItem !== null) {
      const updatedItems = items.map((item) =>
        item.id === editingItem.id
          ? {
              ...item,
              title: newTitle,
              description: newDescription,
              coverImage: newCoverImage,
              lastUpdated: new Date().toISOString(),
            }
          : item
      );
      setItems(updatedItems);
    } else {
      const newItem = {
        id: `item-${items.length}`,
        title: newTitle,
        description: newDescription,
        coverImage: newCoverImage,
        createdAt: new Date().toISOString(),
        lastUpdated: new Date().toISOString(),
        subnotes: [],
      };
      setItems([...items, newItem]);
    }
    setOpenDialog(false);
  };

  const handleNoteClick = (item) => {
    setEditingItem(item);
    setNewTitle(item.title);
    setNewDescription(item.description);
    setNewCoverImage(item.coverImage);
    setSelectedNote(item);
    setTabIndex(0); // Reset tab index to 0 when editing note
    setOpenDialog(true);
  };

  const handleToggleAssistant = () => {
    setIsAssistantOpen(!isAssistantOpen);
  };

  const handleAddSubnote = () => {
    if (selectedNote && newSubnote.trim()) {
      const updatedItems = items.map((item) =>
        item.id === selectedNote.id
          ? {
              ...item,
              subnotes: [
                ...item.subnotes,
                { id: `subnote-${item.subnotes.length}`, text: newSubnote },
              ],
            }
          : item
      );
      setItems(updatedItems);
      setNewSubnote("");
      setTabIndex(selectedNote.subnotes.length); // Show the newly added subnote tab
    }
  };

  const handleDeleteSubnote = (noteId, subnoteId) => {
    const updatedItems = items.map((item) =>
      item.id === noteId
        ? {
            ...item,
            subnotes: item.subnotes.filter(
              (subnote) => subnote.id !== subnoteId
            ),
          }
        : item
    );
    setItems(updatedItems);
    if (tabIndex >= 0) {
      setTabIndex(0); // Reset tab index if subnote is deleted
    }
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <Container maxWidth="md" className={classes.gridContainer}>
      <Typography variant="h4" className={classes.title}>
        Your Dashboard
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided) => (
            <Grid
              container
              spacing={3}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Card
                        className={classes.card}
                        onClick={() => handleNoteClick(item)}
                      >
                        <CardMedia
                          className={classes.cardMedia}
                          image={extractImageUrl(item.coverImage)}
                          title={item.title}
                        />
                        <div className={classes.cardContent}>
                          <Typography variant="h6" className={classes.cardTitle}>
                            {item.title}
                          </Typography>
                          <Typography variant="body2" className={classes.cardDescription}>
                            {item.description}
                          </Typography>
                        </div>
                      </Card>
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <Grid item xs={6} sm={6}>
                <div className={classes.addCard} onClick={handleAddNew}>
                  <IconButton>
                    <AddIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          )}
        </Droppable>
      </DragDropContext>

      {/* Note Editor Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullScreen
        TransitionComponent={Slide}
        keepMounted
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <TextField
              fullWidth
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              placeholder="Enter Title"
              InputProps={{
                style: { fontWeight: "bold", color: "highlight", fontFamily: "Poppins" },
              }}
            />
            <IconButton onClick={() => setOpenDialog(false)} edge="end">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.container}>
            <div className={classes.textEditorContainer} style={{ marginRight: isAssistantOpen ? 0 : 'auto' }}>
              <QuillEditor
                value={newDescription}
                onChange={setNewDescription}
                theme="snow"
                placeholder="Write something..."
              />
            </div>
            {isAssistantOpen && (
              <div className={classes.assistantBox}>
                <Typography variant="h6" color="primary">
                  AI Assistant
                </Typography>
              </div>
            )}
          </div>
          <Button
            className={classes.assistantToggle}
            style={{marginRight:"100px"}}
            onClick={handleToggleAssistant}
          >
            Toggle Assistant
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveNote}
          >
            Save Note
          </Button>
          <TextField
            fullWidth
            label="Add a subnote"
            value={newSubnote}
            onChange={(e) => setNewSubnote(e.target.value)}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={handleAddSubnote}
            className={classes.addSubnoteButton}
          >
            Add Subnote
          </Button>
          {selectedNote && selectedNote.subnotes.length > 0 && (
            <div className={classes.tabContainer}>
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="subnote tabs"
              >
                {selectedNote.subnotes.map((subnote, index) => (
                  <Tab key={subnote.id} label={`Subnote ${index + 1}`} />
                ))}
              </Tabs>
              <div>
                <Typography variant="body1">{selectedNote.subnotes[tabIndex].text}</Typography>
              </div>
            </div>
          )}
          <List className={classes.notesList}>
            {selectedNote &&
              selectedNote.subnotes.map((subnote) => (
                <ListItem
                  key={subnote.id}
                  className={classes.subnoteItem}
                  divider
                >
                  <ListItemText primary={subnote.text} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() =>
                        handleDeleteSubnote(selectedNote.id, subnote.id)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default Widgets;
import React from "react";
import { Container, Grid, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footer: {
    width: "100%",
    fontFamily: "'Poppins', sans-serif",
    padding: theme.spacing(10, 2),
    backgroundColor: "#2563eb",
    color: "white",
  },
  link: {
    textDecoration: "none",
    display: "block",
    margin: theme.spacing(1, 0),
    color: "rgb(223, 211, 211)",
    fontSize: "1rem",
    "&:hover": {
      // color: "rgb(223, 211, 211)",
      textDecoration: "none",
    },
  },
  active: {
    color: "white",
  },
  title: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "1.25rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  container: {
    spacing: 6,
  },
  language: {
    marginTop: theme.spacing(2),
  },
  spacer: {
    width: "100%",
    height: "1px",
    backgroundColor: "white",
    marginTop: theme.spacing(6),
  },
}));

const languages = [
  "العربية",
  "বাংলা",
  "Čeština",
  "Deutsch",
  "Ελληνικά",
  "English",
  "Español",
  "Français",
  "हिन्दी",
  "Magyar",
  "Bahasa Indonesia",
  "Italiano",
  "日本語",
  "한국어",
  "Nederlands",
  "Polski",
  "Português",
  "Română",
  "Русский",
  "తెలుగు",
  "ภาษาไทย",
  "Tagalog",
  "Türkçe",
  "Українською",
  "Tiếng Việt",
  "中文",
];

function Footer() {
  const classes = useStyles();
  return (
    <Box component="footer" className={classes.footer}>
      <Container>
        <Grid
          container
          spacing={6}
          className={classes.container}
          style={{ justifyContent: "space-between" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <Typography variant="h6" className={classes.title}>
              About us
            </Typography>
            <Link href="#" className={classes.link}>
              Courses
            </Link>
            <Link href="#" className={classes.link}>
              Mission
            </Link>
            <Link href="#" className={classes.link}>
              Approach
            </Link>
            <Link href="#" className={classes.link}>
              Efficacy
            </Link>
            <Link href="#" className={classes.link}>
              Team
            </Link>
            <Link href="#" className={classes.link}>
              Research
            </Link>
            <Link href="#" className={classes.link}>
              Careers
            </Link>
            <Link href="#" className={classes.link}>
              Brand guidelines
            </Link>
            <Link href="#" className={classes.link}>
              Store
            </Link>
            <Link href="#" className={classes.link}>
              Press
            </Link>
            <Link href="#" className={classes.link}>
              Investors
            </Link>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Typography variant="h6" className={classes.title}>
              Products
            </Typography>
            <Link href="#" className={classes.link}>
              Explified for Schools
            </Link>
            <Link href="#" className={classes.link}>
              Explified for Business
            </Link>
            <Link href="#" className={classes.link}>
              Explified English Test
            </Link>
            <Link href="#" className={classes.link}>
              Podcast
            </Link>
            <Link href="#" className={classes.link}>
              Explified Math
            </Link>
            <Link
              href="https://web.explified.com"
              target="_blank"
              className={classes.link + " " + classes.active}
            >
              Explified Explore
            </Link>
            <Link href="#" className={classes.link}>
              Super Explified
            </Link>
            <Link href="#" className={classes.link}>
              Gift Super Explified
            </Link>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Typography variant="h6" className={classes.title}>
              Apps
            </Typography>
            <Link href="#" className={classes.link}>
              Explified for Android
            </Link>
            <Link href="#" className={classes.link}>
              Explified for iOS
            </Link>
            <Link href="#" className={classes.link}>
              Explified ABC (iOS)
            </Link>
            <Box mt={5}>
              <Typography variant="h6" className={classes.title}>
                Social
              </Typography>
              <Link href="#" className={classes.link}>
                Blog
              </Link>
              <Link href="#" className={classes.link}>
                Twitter
              </Link>
              <Link href="#" className={classes.link}>
                Instagram
              </Link>
              <Link href="#" className={classes.link}>
                Facebook
              </Link>
              <Link
                href="https://youtube.com/@Explified"
                target="_blank"
                className={classes.link + " " + classes.active}
              >
                YouTube
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Typography variant="h6" className={classes.title}>
              Help and support
            </Typography>
            <Link href="#" className={classes.link}>
              Explified FAQs
            </Link>
            <Link href="#" className={classes.link}>
              Schools FAQs
            </Link>
            <Link href="#" className={classes.link}>
              Explified English Test FAQs
            </Link>
            <Link href="#" className={classes.link}>
              Status
            </Link>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Typography variant="h6" className={classes.title}>
              Privacy and terms
            </Typography>
            <Link href="#" className={classes.link}>
              Community guidelines
            </Link>
            <Link to="/terms-of-service" className={classes.link + " " + classes.active}>
              Terms of Service
            </Link>
            <Link to="/privacy-policy" className={classes.link + " " + classes.active}>
              Privacy Policy
            </Link>
            <Link to="/disclaimer" className={classes.link + " " + classes.active}>
              Disclaimer
            </Link>
            <Link href="#" className={classes.link}>
              Respecting your "do not sell my personal information" rights
            </Link>
          </Grid>
        </Grid>
        <Box className={classes.spacer}></Box>
        <Box mt={5} className={classes.language}>
          <Typography
            variant="h5"
            align="left"
            style={{
              marginBottom: 10,
              fontWeight: "bold",
              fontFamily: "Poppins",
            }}
          >
            Site language:
          </Typography>
          <Box mt={2}>
            <ul
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "left",
                listStyle: "none",
                padding: 0,
              }}
            >
              {languages.map((language, index) => (
                <li key={index} style={{ margin: "0 16px 0 0" }}>
                  <Typography variant="body1" color="inherit" style={{ cursor: "pointer", fontFamily: "Poppins", color: "rgb(223, 211, 211)" }}>
                    {language}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        </Box>
        <Box className={classes.spacer}></Box>
        <Box>
          <Typography 
          variant="h5"
          style={{
            marginTop: 20, marginBottom: 10, fontWeight: "bold", fontFamily: "Poppins"
          }}>Disclaimer:</Typography>
          <p>
          GMAT® is a registered trademark of the Graduate Management Admission Council (GMAC). This website is not endorsed or approved by GMAC. GRE®, TOEFL®, and Praxis® are registered trademarks of Educational Testing Service (ETS). This website is not endorsed or approved by ETS. SAT® is a registered trademark of the College Board, which was not involved in the production of, and does not endorse this product. LSAT® is a registered trademark of the Law School Admission Council, Inc. This website is not endorsed or approved by the LSAC. ACT® is a registered trademark of ACT, inc. This website is not endorsed or approved by ACT, inc. MCAT is a registered trademark of the Association of American Medical Colleges (AAMC). This website is not endorsed or approved by AAMC.
          </p>
        </Box>

      </Container>
    </Box>
  );
}

export default Footer;
import React, { useEffect, useState } from 'react';
import {
  TextField,
  IconButton,
  Typography,
  Button,
  Divider,
  Paper,
  Box,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  AttachFile as AttachFileIcon,
  Add as AddIcon,
  Comment as CommentIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Add as AddNoteIcon,
} from '@material-ui/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { RiClosedCaptioningLine } from 'react-icons/ri';
import axios from 'axios';

const Notes = () => {
  const [notes, setNotes] = useState([] | null);
  const [newNoteTitle, setNewNoteTitle] = useState('');
  const [editorContent, setEditorContent] = useState('');
  const [comment, setComment] = useState('');
  const [image, setImage] = useState(null);
  const [link, setLink] = useState('');
  const [subNotes, setSubNotes] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingNoteId, setEditingNoteId] = useState(null); // Track the note ID being edited
 

  useEffect(() => {
    fetchNotes();
  }, []);


  
 

  const fetchNotes = async () => {
    try {
      const userId = localStorage.getItem('authtoken');

      const response = await axios({
        method: 'POST',
        url: 'http://localhost:5000/api/v1/notes/getnotes',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          userId,
        }
      });
      console.log(response);

      if(!response.ok) {
        throw new Error('Failed to fetch notes');
      }

      const data = response.data;
      console.log(data);
      
      setNotes(Array.isArray(data.notes) ? data.notes : []);
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };

  const handleAddOrUpdateNote = async (event) => {
    event.preventDefault();
    if (newNoteTitle.trim() && editorContent.trim()) {
      if (editingNoteId) {
        // Update existing note
        await updateNote(editingNoteId, newNoteTitle, editorContent, comment, subNotes);
      } else {
        // Create new note
        await createNote(newNoteTitle, editorContent, comment, subNotes);
      }
      resetForm();
    }
  };

  const createNote = async (title, content, comment, subNotes) => {

    const userId = localStorage.getItem('authtoken');
    console.log(userId);
    
    try {
      const response = await fetch('http://localhost:5000/api/v1/notes/createNote', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, title, content, comment, subNotes }),
      });
      // console.log(response);
      
      if (response.ok) {
        const { noteId } = await response.json();
        setNotes([...notes, { id: noteId, title, content, comments: [comment], subNotes}]);
        // console.log("first", noteId,title, content, comments: [comment], subNotes);
      } else {
        console.error('Failed to create note');
      }
    } catch (error) {
      console.error('Error creating note:', error);
    }
  };

  const updateNote = async (id, title, content, comment, subNotes) => {
    try {
      const response = await fetch(`http://localhost:5000/api/v1/notes/updateNote`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id, title, content, comment, subNotes }),
      });

      if (response.ok) {
        setNotes(notes.map(note => (note.id === id ? { ...note, title, content, comments: [comment], subNotes} : note)));
      } else {
        console.error('Failed to update note');
      }
    } catch (error) {
      console.error('Error updating note:', error);
    }
  };

  const deleteNote = async (id) => {
    try {
      const response = await fetch(`http://localhost:5000/api/v1/notes/deleteNote/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setNotes(notes.filter(note => note.id !== id));
      } else {
        console.error('Failed to delete note');
      }
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  };

  const handleAddSubNote = (index) => {
    const newSubNote = prompt('Enter sub-note:');
    if (newSubNote) {
      setNotes([...subNotes, newSubNote]);
    }
  };

  const handleDeleteNote = (id) => {
    deleteNote(id);
  };

  const handleEditNote = (note) => {
    setNewNoteTitle(note.title);
    setEditorContent(note.content);
    setComment(note.comments.join(', '));
    setSubNotes(note.subNotes || []);
    setEditingNoteId(note.id); // Set the note ID being edited
  };



  const resetForm = () => {
    setNewNoteTitle('');
    setEditorContent('');
    setComment('');
    setSubNotes([]);
    setEditingNoteId(null); // Reset editing note ID
  };

  return (
    <Box p={3} display="flex" flexDirection="column" height="100vh">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Notes
        </Typography>
        <IconButton
          color="primary"
          onClick={() => resetForm()}
          style={{ alignSelf: 'flex-end' }}
        >
          <AddNoteIcon />
        </IconButton>
      </Box>

      <Box display="flex" flexDirection="column" flex={1}>
        <Paper elevation={3} style={{ padding: 16, marginBottom: 24 }}>
          <form onSubmit={handleAddOrUpdateNote}>
            <TextField
              label="Note Title"
              variant="outlined"
              fullWidth
              margin="normal"
              value={newNoteTitle}
              onChange={(e) => setNewNoteTitle(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton color="primary" type="submit">
                      <SaveIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ReactQuill
              style={{ height: '200px', width: '100%' }}
              value={editorContent}
              onChange={setEditorContent}
              theme="snow"
              placeholder="Write something..."
            />
            <Box style={{ marginTop: 16, marginBottom: 20 }}>
              <TextField
                label="Add a Comment"
                variant="outlined"
                fullWidth
                margin="normal"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton color="primary" onClick={() => setComment(comment)}>
                        <CommentIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
                <Button color='primary' onClick={handleAddSubNote} style={{marginTop : 10}}>
                  Add Sub-note
                </Button>
              </Box>
            <IconButton
              color="primary"
              type="submit"
              style={{
                backgroundColor: 'blue',
                color: 'white',
                borderRadius: '5px',
                marginTop: '5px',
              }}
            >
              SAVE
            </IconButton>
          </form>
        </Paper>

        <Box flex={1}>
  <Typography variant="h6" gutterBottom>
    Existing Notes
  </Typography>
  {notes.length > 0 ? (
    notes.map((note, index) => (
      <Paper key={note.id} elevation={3} style={{ padding: 16, marginBottom: 16 }}>
        <Typography variant="h6">
          {note.title}
          <Box display="flex" justifyContent="flex-end">
            <IconButton color="secondary" onClick={() => handleEditNote(note)}>
              <EditIcon />
            </IconButton>
            <IconButton color="secondary" onClick={() => handleDeleteNote(note.id)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Typography>
        <Divider style={{ margin: '16px 0' }} />
        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: note.content }} />
        {note.image && (
          <img src={note.image} alt="Note" style={{ maxWidth: '100%', marginTop: 10 }} />
        )}
        {note.comments && note.comments.length > 0 && note.comments.map((comment, i) => (
          <Typography variant="body2" key={i}>
            Comment: {comment}
          </Typography>
        ))}
        {note.subNotes && note.subNotes.length > 0 && (
          <List dense>
            {note.subNotes.map((subNote, subIndex) => (
              <ListItem key={subIndex}>
                <ListItemText primary={subNote} />
              </ListItem>
            ))}
          </List>
        )}
        <Box display="flex" justifyContent="flex-end">
          <Button color="primary" onClick={() => handleAddSubNote(index)}>
            Add Sub-Note
          </Button>
        </Box>
      </Paper>
    ))
  ) : (
    <Typography>No existing notes. Please add a note.</Typography>
  )}
</Box>
      </Box>
    </Box>
  );
};

export default Notes;

import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as images from "../../assets/images"; // Replace with your image paths
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    marginBottom: theme.spacing(2),
    fontFamily: "Poppins",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "12px",
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
  media: {
    width: 150,
    height: 150,
    borderRadius: "8px",
  },
  content: {
    flex: "1 0 auto",
    fontFamily: "Poppins",
    paddingLeft: theme.spacing(2),
  },
  button: {
    display:"flex-row",
    alignSelf: "center",
    fontFamily: "Poppins",
    background: "linear-gradient(45deg, #23b5b5, #23b5b5)",
    borderRadius: "8px",
    color: "white",
    "&:hover": {
      backgroundColor: "#1a8c8c",
    },
  },
  searchField: {
    marginBottom: theme.spacing(2),
    fontFamily: "Poppins",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
  },
}));

const Apps = () => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");

  const appsData = [
    {
      id: 1,
      name: "Explified for Windows",
      description: "This is the Windows version of the Application.",
      image: images.windows,
      link: "https://apps.microsoft.com/home?hl=en-us&gl=IN",
    },
    {
      id: 2,
      name: "Explified for Android",
      description: "This is the Android version of the Application.",
      image: images.android,
      link: "https://play.google.com/store/games?hl=en",
    },
    {
      id: 3,
      name: "Explified for Apple",
      description: "This is the Apple version of the Application.",
      image: images.apple,
      link: "https://www.apple.com/in/app-store/",
    },
    {
      id: 4,
      name: "Explified for Chrome",
      description: "This is the Google Chrome version of the Application.",
      image: images.chrome,
      link: "https://chromewebstore.google.com/",
    },
  ];

  const filteredApps = appsData.filter((app) =>
    app.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div>
      <TextField
        className={classes.searchField}
        label="Search..."
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <Grid container direction="column" spacing={2}>
        {filteredApps.map((app) => (
          <Grid item key={app.id}>
            <Card className={classes.card} elevation={3}>
              <CardMedia
                className={classes.media}
                image={app.image}
                title={app.name}
              />
              <CardContent className={classes.content}>
                <Typography variant="h6" style={{ fontFamily: "Poppins", color: "#23b5b5" }}>
                  {app.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontFamily: "Poppins" }}
                >
                  {app.description}
                </Typography>
              </CardContent>
              {app.link.startsWith("http") ? (
                <a href={app.link} target="_blank" rel="noopener noreferrer">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    Coming Soon
                  </Button>
                </a>
              ) : (
                <Link to={app.link}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    Coming Soon
                  </Button>
                </Link>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Apps;

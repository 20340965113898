import React, { useState } from 'react';
import { Button, Dialog, DialogContent, Typography, Snackbar, IconButton, makeStyles, TextField, FormControlLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AppIcon from '../../assets/images/explified.jpeg'; // Update this with the path to your app icon
import BackgroundImage from '../../assets/images/background.jpg'; // Update this with the path to your background image
import LoginModal from './LoginModal';
import axios from 'axios';
import { CheckBox } from '@material-ui/icons';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    width: '100vw',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: '#fff',
  },
  appIconWrapper: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    marginLeft: theme.spacing(2),
  },
  appIcon: {
    width: 80,
    height: 80,
    marginRight: theme.spacing(1),
  },
  appText: {
    fontSize: '4rem',
    fontWeight: 'bold',
    color: 'black',
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    padding: theme.spacing(4),
    color: '#fff',
  },
  description: {
    maxWidth: '50%',
    color: 'black',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor: '#fff',
    borderRadius: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
    paddingTop: '75px',
    paddingBottom: '75px',
  },
  button: {
    margin: theme.spacing(1),
    color: '#003262',
    fontSize: "17px",
    fontWeight: 'bold',
    height: "50px",
    background: "linear-gradient(-45deg, #b597f6, #96c6ea)",
    marginTop: "15px",
  },
  primaryButton: {
    backgroundColor: '#23b5b5',
  },
  secondaryButton: {
    backgroundColor: '#f57c00',
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    fontFamily: "Poppins, sans-serif"
  },
  textField: {
    fontFamily: "Poppins, sans-serif",
    "& .MuiInputBase-root": {
      height: "100%",
      width: "500px",
      fontFamily: "Poppins",
    },
    "& .MuiInputLabel-root": {
      fontFamily: "Poppins",
    },
    marginBottom: "15px"
  },
  loginText: {
    color: "#000",
    fontSize: "32pt",
    fontWeight: 'bolder',
    alignSelf: 'center',
    marginBottom: "25px",
    background: "linear-gradient(45deg, #919bff, #133a94)",
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text'
  },
  textBtn: {
    display: "flex",
    justifyContent: 'space-between',
    marginTop: "10px",
    marginBottom: "10px"
  },
  checkbox: {
    color: "#000",
    marginLeft: "0px",
    fontFamily: "Poppins, sans-serif",
  },
  link: {
    color: "#000",
    cursor: 'pointer'
  },
  switchText: {
    color: "#6e78ff",
    fontSize: "13px",
    alignSelf: "center",
    cursor: 'pointer'
  }
}));

function LoginPopup({ open, onClose }) {
  const classes = useStyles();
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();

    try{
      const response = await axios.post("http://localhost:5000/api/v1/auth/login", {
        email,
        password,
      });
    

      //if login is successful, store the token in local storage
      const { token } = response.data;
      console.log(response.data);
      localStorage.setItem("authtoken", token);

      setSnackbarMessage("Login successful!");
      setSnackbarOpen(true);

      history.push("/dashboard");
      
    }
    catch(error){
      setSnackbarMessage(error.response ? error.response.data.message : "An error occurred during login.");
      setSnackbarOpen(true);
    }
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleLoginClick = () => {
    setOpenLoginModal(true);
  };

  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
        aria-labelledby="login-popup-title"
        classes={{ paper: classes.dialogPaper }}
      >
        <IconButton edge="end" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <div className={classes.appIconWrapper}>
          <img src={AppIcon} alt="App Icon" className={classes.appIcon} />
          <Typography className={classes.appText} style={{fontFamily: "Poppins"}}>
            Explified
          </Typography>
        </div>
        <DialogContent>
          <div className={classes.contentWrapper}>
            <div className={classes.description}>
              <Typography variant="h3" style={{fontWeight: "bold", fontFamily: "Poppins"}}>
              Welcome Back! 
              </Typography>
              <Typography variant="h4" paragraph>
              Ready to Continue Your Language Journey?
              </Typography>
            </div>
            <div className={classes.buttonGroup}>
              <form className={classes.form}>
                <p className={classes.loginText}>Login</p>
                <TextField
                  label="Email"
                  variant="outlined"
                  type='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={classes.textField}
                />
                <TextField
                  label="Password"
                  variant="outlined"
                  type='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={classes.textField}
                />
                <div className={classes.textBtn}>
                  <FormControlLabel
                    control={<CheckBox color='primary' />}
                    label="Remember me"
                    className={classes.checkbox}
                  />
                  <p variant='body2' className={classes.link} onClick={() => alert("Forgot Password Clicked !!")}>Forgot Password!</p>
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.button}
                  onClick={handleLogin}
                >
                  Login
                </Button>
                <p variant='body2' className={classes.switchText} onClick={() => alert("")}>Don't have an account, Sign Up !</p>
              </form>
            </div>
          </div>
        </DialogContent>
        <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        ContentProps={{
          className: classes.snackbar,
          style: { backgroundColor: "green" },
        }}
        action={
          <>
            <IconButton
              size="small"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      </Dialog>

      <LoginModal isOpen={openLoginModal} onClose={handleCloseLoginModal} />
    </>
  );
}

export default LoginPopup;

import React, { useState } from "react";
import {
  Container,
  Paper,
  Avatar,
  TextField,
  IconButton,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import LockIcon from "@material-ui/icons/Lock";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import userImage from "../../assets/images/user.jpg";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  profileImage: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    marginBottom: theme.spacing(2),
    height: 200,
    width: 200,
  },
  paper: {
    padding: theme.spacing(2),
    width: "100%",
    maxWidth: 600,
    marginBottom: theme.spacing(2),
  },
  fieldContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  textField: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  iconButton: {
    padding: 10,
  },
  section: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const Profile = () => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState({
    name: false,
    email: false,
    password: false,
    address: false,
  });
  const [profile, setProfile] = useState({
    name: "John Doe",
    email: "john.doe@example.com",
    password: "********",
    address: "1234 Main St, Springfield, USA",
  });

  const handleEditClick = (field) => {
    setIsEditing((prevState) => ({ ...prevState, [field]: !prevState[field] }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfile((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <Container className={classes.container}>
      <Avatar
        className={classes.profileImage}
        src={userImage}
        alt="Profile Image"
      />
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          {["name", "email", "password", "address"].map((field) => (
            <Grid item xs={12} key={field} className={classes.fieldContainer}>
              <TextField
                className={classes.textField}
                label={field.charAt(0).toUpperCase() + field.slice(1)}
                name={field}
                value={profile[field]}
                onChange={handleInputChange}
                disabled={!isEditing[field]}
                variant="outlined"
                fullWidth
              />
              <IconButton
                className={classes.iconButton}
                onClick={() => handleEditClick(field)}
              >
                {isEditing[field] ? <SaveIcon /> : <EditIcon />}
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          Account Settings
        </Typography>
        <Box className={classes.section}>
          <Typography variant="subtitle1">
            <LockIcon className={classes.icon} />
            Change Password
          </Typography>
        </Box>
        <Box className={classes.section}>
          <Typography variant="subtitle1">
            <ExitToAppIcon className={classes.icon} />
            Logout
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default Profile;

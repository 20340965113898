import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  IconButton,
  Collapse,
  Avatar,
  Snackbar,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SchoolIcon from "@material-ui/icons/School";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LinkIcon from "@material-ui/icons/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ToysIcon from "@material-ui/icons/Toys";
import AppsIcon from "@material-ui/icons/Apps";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NotesIcon from '@material-ui/icons/Notes';
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    boxSizing: "border-box",
    backgroundColor: "#23b5b5",
    color: "white",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerPaperClose: {
    width: 72,
    [theme.breakpoints.up("sm")]: {
      width: 72,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#23b5b5",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2.4),
    paddingLeft: theme.spacing(2),
  },
  icon: {
    color: "white",
  },
  listItemText: {
    opacity: (props) => (props.open ? 1 : 0),
    transition: "opacity 0.3s",
  },
  selected: {
    color: "#ffd700",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const SideBar = ({ selectedItem, onItemSelected, open, setOpen }) => {
  const [showBack, setShowBack] = useState(false);
  const [dashboardOpen, setDashboardOpen] = useState(false);
  const [tutorsOpen, setTutorsOpen] = useState(false);
  const classes = useStyles({ open });
  const history = useHistory();

  const handleLogout = async () => {
    try {
      await axios.post("https://backend-explified.web.app/api/v1/auth/logout");
      localStorage.removeItem("authtoken");
      history.push("/");
    } catch (error) {
      console.error("Logout Failed", error);
    }
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleBeATutorClick = () => {
    setOpen(false);
    setShowBack(true);
    onItemSelected("Be A Tutor");
  };

  const handleBackToStudentClick = () => {
    setShowBack(false);
    setOpen(true);
    onItemSelected("Dashboard");
  };

  const handleProfileClick = () => {
    onItemSelected("Profile");
  };

  const handleTutorsClick = () => {
    setTutorsOpen(!tutorsOpen);
    setDashboardOpen(false); // Ensure Dashboard section is closed when Tutors is opened
    onItemSelected("Tutors");
  };

  const handleDashBoardClick = () => {
    setDashboardOpen(!dashboardOpen);
    setTutorsOpen(false); // Ensure Tutors section is closed when Dashboard is opened
    onItemSelected("Dashboard");
  };

  const handleNotesClick = () => {
    onItemSelected("Notes");
  };

  const menuItems = [
    {
      text: "Dashboard",
      icon: <DashboardIcon />,
      onClick: handleDashBoardClick,
      nestedItems: [
        { text: "Notes", icon: <NotesIcon />, onClick: handleNotesClick },
      ],
    },
    { text: "Stream", icon: <LinkIcon /> },
    { text: "Courses", icon: <SchoolIcon /> },
    { text: "Apps", icon: <AppsIcon /> },
    { text: "Playground", icon: <ToysIcon /> },
    {
      text: "Tutors",
      icon: <SchoolIcon />,
      onClick: handleTutorsClick,
      nestedItems: [
        { text: "Be A Tutor", icon: <PersonAddIcon />, onClick: handleBeATutorClick },
      ],
    },
  ];

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: open ? classes.drawerPaper : classes.drawerPaperClose,
      }}
      open={open}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Toolbar className={classes.toolbar}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={handleDrawerToggle}
            className={classes.icon}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          {open && !showBack && (
            <Typography
              variant="h6"
              noWrap
              onClick={handleProfileClick}
              style={{ cursor: "pointer" }}
              endIcon={<AccountCircleIcon />}
            >
              Profile
            </Typography>
          )}
        </div>
        {open && (
          <Avatar
            alt="Profile Picture"
            src="/path/to/dummy/image.jpg"
            className={classes.avatar}
            onClick={handleProfileClick}
          />
        )}
      </Toolbar>
      <List>
        {!showBack ? (
          menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem
                button
                onClick={
                  item.onClick ||
                  (!item.external ? () => onItemSelected(item.text) : undefined)
                }
                className={selectedItem === item.text ? classes.selected : ""}
              >
                <ListItemIcon
                  className={
                    selectedItem === item.text ? classes.selected : classes.icon
                  }
                >
                  {item.icon}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary={item.text}
                    className={classes.listItemText}
                  />
                )}
                {item.nestedItems && ( // Show expand icon if there are nested items
                  open && (item.text === "Dashboard" ? (dashboardOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />) : (item.text === "Tutors" ? (tutorsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />) : null))
                )}
              </ListItem>
              {item.nestedItems && (
                <Collapse in={item.text === "Dashboard" ? dashboardOpen : item.text === "Tutors" ? tutorsOpen : false} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.nestedItems.map((nestedItem, nestedIndex) => (
                      <ListItem
                        button
                        key={nestedIndex}
                        onClick={nestedItem.onClick}
                        className={classes.nested}
                      >
                        <ListItemIcon className={classes.icon}>
                          {nestedItem.icon}
                        </ListItemIcon>
                        <ListItemText primary={nestedItem.text} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))
        ) : (
          <ListItem button onClick={handleBackToStudentClick}>
            <ListItemIcon>
              <ArrowBackIcon className={classes.icon} />
            </ListItemIcon>
            {open && (
              <ListItemText
                primary="Back to Student"
                className={classes.listItemText}
              />
            )}
          </ListItem>
        )}
      </List>
      {!showBack && (
        <List style={{ marginTop: "auto" }}>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon className={classes.icon} />
            </ListItemIcon>
            {open && (
              <ListItemText
                primary="Logout"
                secondary="tech@explified.com"
                className={classes.listItemText}
              />
            )}
          </ListItem>
        </List>
      )}
    </Drawer>
  );
};

export default SideBar;

import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPlayer from "react-player";
import {
  Card,
  CardMedia,
  CardContent,
  Grid,
  Typography,
  Button,
  Container,
  CircularProgress,
  Dialog,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import CategoryIcon from "@material-ui/icons/Category";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: theme.spacing(4),
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    padding: theme.spacing(2),
    boxShadow: theme.shadows[3],
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
      boxShadow: theme.shadows[6],
    },
  },
  cardMedia: {
    width: "100%",
    height: 0,
    paddingTop: "56.25%", 
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
    textAlign: "center",
  },
  videoTitle: {
    fontWeight: "bold",
    fontFamily: "Poppins",
    marginBottom: theme.spacing(1),
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  videoDescription: {
    fontFamily: "Poppins",
    marginBottom: theme.spacing(2),
    color: "#555",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  streamButton: {
    backgroundColor: "#2563eb",
    color: "white",
    "&:hover": {
      backgroundColor: "#1e4db7",
    },
    fontFamily: "Poppins",
  },
  iconText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(1),
    color: "#666",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
  },
  dialogContent: {
    position: "relative",
    padding: 0,
    overflow: "hidden",
    backgroundColor: "black",
    height: "calc(100vh - 64px)",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: "white",
    zIndex: 1,
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%", 
    height: "100%",
    width: "100%",
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100% !important", 
    width: "100% !important", 
  },
}));

const Stream = () => {
  const classes = useStyles();
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        setLoading(true);
        setError(false);

        const { data } = await axios.get(
          "https://backend-explified.web.app/api/v1/video/get",
          {
            cancelToken: new axios.CancelToken((cancel) => (cancelRequest = cancel)),
          }
        );

        setVideos(data.data);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) return;
        setError(true);
        setLoading(false);
        console.error(error);
      }
    };

    let cancelRequest;
    fetchVideos();

    return () => {
      if (cancelRequest) {
        cancelRequest();
      }
    };
  }, []);

  const handleClose = () => {
    setSelectedVideo(null);
  };

  if (loading) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className={classes.loader}>
        <Typography variant="h6" color="error">
          Failed to load videos. Please try again later.
        </Typography>
      </div>
    );
  }

  return (
    <Container maxWidth="md" className={classes.gridContainer}>
      <div className={classes.container}>
        <Typography variant="h4" className={classes.videoTitle}>
          Stream
        </Typography>
        <Button
          href="https://stream.explified.com/"
          target="_blank"
          className={classes.streamButton}
        >
          Go to Stream
        </Button>
      </div>
      <Grid container spacing={3}>
        {videos.map((video) => (
          <Grid item xs={12} sm={6} md={4} key={video.videoID}>
            <Card
              className={classes.card}
              onClick={() => setSelectedVideo(video.videoID)}
            >
              <CardMedia
                image={`https://img.youtube.com/vi/${video.videoID}/hqdefault.jpg`}
                className={classes.cardMedia}
                title={video.title}
              />
              <CardContent className={classes.cardContent}>
                <Typography variant="h6" className={classes.videoTitle}>
                  {video.title}
                </Typography>
                <Typography variant="body2" className={classes.videoDescription}>
                  {video.description}
                </Typography>
                <div className={classes.iconText}>
                  <CategoryIcon style={{ marginRight: 4 }} />
                  <Typography variant="body2">{video.category}</Typography>
                </div>
                <div className={classes.iconText}>
                  <AccessTimeIcon style={{ marginRight: 4 }} />
                  <Typography variant="body2">{video.duration}</Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={Boolean(selectedVideo)}
        onClose={handleClose}
        fullScreen
      >
        <div className={classes.dialogContent}>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <ReactPlayer
            url={`https://www.youtube.com/watch?v=${selectedVideo}`}
            playing
            controls
            width="100%"
            height="100%"
          />
        </div>
      </Dialog>
    </Container>
  );
};

export default Stream;

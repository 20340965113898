import React from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { courses, courses2 } from "../../assets/images/index";
const coursesData = [
  {
    id: 1,
    name: "React for Beginners",
    progress: 75,
    image: courses,
  },
  {
    id: 2,
    name: "Advanced JavaScript",
    progress: 45,
    image: courses2,
  },
  {
    id: 3,
    name: "Python Data Science",
    progress: 90,
    image: courses2,
  },
  {
    id: 4,
    name: "Introduction to Machine Learning",
    progress: 30,
    image: courses,
  },
  {
    id: 5,
    name: "Web Development Bootcamp",
    progress: 60,
    image: courses2,
  },
  {
    id: 6,
    name: "UI/UX Design Fundamentals",
    progress: 50,
    image: courses,
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  courseCard: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    height: "100%",
    maxWidth: 300,
    fontFamily:'Poppins',
  },
  courseImage: {
    width: "100%",
    height: 150,
    objectFit: "cover",
    marginBottom: theme.spacing(2),
  },
  progressContainer: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  progressText: {
    marginTop: theme.spacing(0.5),
    textAlign: "right", 
    fontFamily:"Poppins"
  },
}));

const CourseCard = ({ course }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.courseCard}>
      <img
        src={course.image}
        alt={course.name}
        className={classes.courseImage}
      />
      <Typography variant="h6" gutterBottom  style={{fontFamily:"Poppins"}}>
        {course.name}
      </Typography>
      <Box className={classes.progressContainer}>
        <LinearProgress variant="determinate" value={course.progress} />
        <Typography className={classes.progressText}>
          {course.progress}%
        </Typography>
      </Box>
    </Paper>
  );
};

const Courses = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography variant="h4" gutterBottom style={{fontFamily:"Poppins"}}>
        Your Courses
      </Typography>
      <Grid container spacing={3}>
        {coursesData.map((course) => (
          <Grid item xs={12} sm={6} md={4} key={course.id}>
            <CourseCard course={course} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Courses;

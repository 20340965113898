import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  parallaxSection: {
    height: "500px",
    background: "linear-gradient(0deg, #568BFF, #2563eb)",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Poppins, sans-serif",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    padding: theme.spacing(4),
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
  },
  courseHeading: {
    color: "#fff",
    marginBottom: theme.spacing(3),
    fontWeight: "bold",
    fontFamily: "Poppins, sans-serif",
  },
  courseButtons: {
    fontFamily: "Poppins, sans-serif",
    "& > *": {
      margin: theme.spacing(1),
      color: theme.palette.primary.contrastText,
    },
  },
}));

const ParallaxSection = () => {
  const classes = useStyles();

  return (
    <div className={classes.parallaxSection}>
      <Container className={classes.overlay} maxWidth="md">
        <Typography variant="h3" className={classes.courseHeading} style={{fontFamily: "Poppins, sans-serif"}}>
          Explore Our Courses
        </Typography>
        <div className={classes.courseButtons}>
          <Button variant="contained" size="large" color="primary">
            GRE
          </Button>
          <Button variant="contained" size="large" color="primary">
            GMAT
          </Button>
          <Button variant="contained" size="large" color="primary">
            TOEFL
          </Button>
          <Button variant="contained" size="large" color="primary">
            SAT
          </Button>
          <Button variant="contained" size="large" color="primary">
            ACT
          </Button>
          <Button variant="contained" size="large" color="primary">
            LSAT
          </Button>
          <Button variant="contained" size="large" color="primary">
            MCAT
          </Button>
          <Button variant="contained" size="large" color="primary">
            IELTS
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default ParallaxSection;
